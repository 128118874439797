import React, { FC, PropsWithChildren } from "react";
import {
  MdAccessTimeFilled,
  MdLocationOn,
  MdOutlineDocumentScanner,
} from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import { Box, Stack, List, ListItemDecorator, ListItem } from "@mui/joy";
import { Logo } from "@components/Logo";
import { Email, Mobile } from "@components/contacts";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import EPRA_logo from "@media/images/logos/EPRA_logo.png";
import {
  BottomSection,
  FooterWrapper,
  TopSection,
  FooterNavigation,
  LogoWrapper,
  NavSectionTitle,
  NavSectionRow,
  NavSectionRowItem as StyledNavSectionRowItem,
  CompanyName,
  BottomSectionTitle,
  BottomSectionItem,
  ContactsRow,
  SocialMediaLink,
} from "./styles";
import { Link } from "gatsby";

const NavSectionRowItem: FC<PropsWithChildren<{ to: string }>> = ({
  to,
  children,
}) => {
  return (
    <StyledNavSectionRowItem>
      <Link to={to} style={{ width: "100%", height: "100%" }}>
        {children}
      </Link>
    </StyledNavSectionRowItem>
  );
};

export const Footer: React.FC = () => {
  return (
    <FooterWrapper component="footer">
      <TopSection justifyContent={"center"}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          justifyContent={"space-between"}
          className="content-wrapper"
          width="100%"
        >
          <LogoWrapper>
            <Box display={{ xs: "block", sm: "none" }}>
              <Logo height={100} />
            </Box>
            <Box display={{ xs: "none", sm: "block", md: "none" }}>
              <Logo height={150} />
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <Logo height={200} />
            </Box>
          </LogoWrapper>
          <FooterNavigation direction={"row"} flexWrap={"wrap"} gap="20px">
            <NavSectionRow>
              <NavSectionTitle>SERVICES</NavSectionTitle>
              <NavSectionRowItem to="">Installation</NavSectionRowItem>
              <NavSectionRowItem to="">Maintenance</NavSectionRowItem>
              <NavSectionRowItem to="">Consultation</NavSectionRowItem>
            </NavSectionRow>
            <NavSectionRow>
              <NavSectionTitle>SOLUTIONS</NavSectionTitle>
              <NavSectionRowItem to="/solutions/home-owners/">
                Home Owners
              </NavSectionRowItem>
            </NavSectionRow>

            <NavSectionRow>
              <NavSectionTitle>SHOP</NavSectionTitle>
              <NavSectionRowItem to="/shop/solar-panels">
                Solar Panels
              </NavSectionRowItem>
              <NavSectionRowItem to="">Inverters</NavSectionRowItem>
              <NavSectionRowItem to="/shop/solar-batteries">
                Batteries
              </NavSectionRowItem>
              <NavSectionRowItem to="/shop/solar-accessories">
                Accessories
              </NavSectionRowItem>
              <NavSectionRowItem to="/shop/solar-flood-lights/">
                Solar Lights
              </NavSectionRowItem>
              <NavSectionRowItem to="/shop/portable-power-stations/">
                Portable Power Stations
              </NavSectionRowItem>
            </NavSectionRow>
            <NavSectionRow>
              <NavSectionTitle>COMPANY</NavSectionTitle>
              <NavSectionRowItem to="/about/company/">
                About Us
              </NavSectionRowItem>
              <NavSectionRowItem to="/about/contacts/">
                Contact Us
              </NavSectionRowItem>
            </NavSectionRow>
          </FooterNavigation>
        </Stack>
      </TopSection>
      <BottomSection>
        <Stack className="content-wrapper" gap={"20px"}>
          <CompanyName fontSize={"24px"}>
            GREATDEAL SOLAR SOLUTIONS LTD
          </CompanyName>
          <Stack direction={"row"} flexWrap={"wrap"} gap={"30px 20px"}>
            <List>
              <BottomSectionTitle>
                <ListItemDecorator>
                  <MdLocationOn size={20} />
                </ListItemDecorator>
                LOCATION
              </BottomSectionTitle>
              <BottomSectionItem>
                2nd Floor Rubis House, at Thindigua{" "}
              </BottomSectionItem>
              <BottomSectionItem>along Kiambu Road, </BottomSectionItem>
              <BottomSectionItem>Nairobi, Kenya</BottomSectionItem>
            </List>
            <List>
              <BottomSectionTitle>
                <ListItemDecorator>
                  <MdOutlineDocumentScanner size={20} />
                </ListItemDecorator>
                LICENSED BY
              </BottomSectionTitle>
              <BottomSectionItem>
                <img src={EPRA_logo} height={"46px"} />
              </BottomSectionItem>
            </List>
            <List>
              <BottomSectionTitle>
                <ListItemDecorator>
                  <MdAccessTimeFilled size={20} />
                </ListItemDecorator>
                WORKING HOURS
              </BottomSectionTitle>
              <BottomSectionItem>
                Mon - Fri (<b>9AM - 5PM</b>)
              </BottomSectionItem>
              <BottomSectionItem>
                SAT (<b>9AM - 1PM</b>)
              </BottomSectionItem>
              <BottomSectionItem>
                SUN (<b>Closed</b>)
              </BottomSectionItem>
            </List>
            <ContactsRow>
              <ListItem>
                <Mobile />
              </ListItem>
              <ListItem>
                <Email />
              </ListItem>
              <ListItem>
                <Stack direction={"row"} gap={"16px"}>
                  <SocialMediaLink>
                    <FaFacebook size={24} />
                  </SocialMediaLink>
                  <SocialMediaLink>
                    <FaInstagram size={24} />
                  </SocialMediaLink>
                  <SocialMediaLink>
                    <FaXTwitter size={24} />
                  </SocialMediaLink>
                </Stack>
              </ListItem>
            </ContactsRow>
          </Stack>
        </Stack>
      </BottomSection>
    </FooterWrapper>
  );
};

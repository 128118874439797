import React, { PropsWithChildren } from "react";
import { Box, Stack } from "@mui/joy";
import Header from "./Header";
import Footer from "./Footer";

export const PageLayout: React.FC<
  PropsWithChildren<{ hideHeader?: boolean }>
> = ({ children, hideHeader }) => {
  return (
    <Stack>
      {!hideHeader && <Header />}
      <Box component="main">{children}</Box>
      <Footer />
    </Stack>
  );
};

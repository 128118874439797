import React from "react";
import { Box, ListItem, ListItemDecorator, Stack, Typography } from "@mui/joy";
import { MdOutlineAccessTime } from "react-icons/md";
import { OurWorkHoursWrapper, ItemsWrapper } from "./styles";
import { SectionTitle } from "../SectionTitle";

const Entry: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  return (
    <ListItem>
      <ListItemDecorator>
        <MdOutlineAccessTime />
      </ListItemDecorator>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Typography width={{ xs: "100%", sm: "200px", md: "224px" }}>
          {title}
        </Typography>
        <Typography fontWeight={"bold"}>{value}</Typography>
      </Stack>
    </ListItem>
  );
};

export const OurWorkHours = () => {
  return (
    <OurWorkHoursWrapper className="content-wrapper" id="our_work_hours">
      <SectionTitle>Our Work Hours</SectionTitle>
      <Box padding={{ xs: "8px", lg: "40px" }}>
        <ItemsWrapper>
          <Entry
            title="MONDAY - FRIDAY"
            value="Open from 9AM (Morning) to 5PM (Evening)"
          />
          <Entry
            title="SATURDAY"
            value="Open from 9AM (Morning) to 1PM (Mid-day)"
          />
          <Entry title="SUNDAY & HOLIDAYS" value="Closed" />
        </ItemsWrapper>
      </Box>
    </OurWorkHoursWrapper>
  );
};

import React from "react";
import { Stack } from "@mui/joy";
import { DesktopNavigation, MobileNavigation } from "@components/navigation";
import { Logo } from "@components/Logo";
import { Background, Wrapper } from "./styles";
import { Link } from "gatsby";

export const Header: React.FC = () => {
  return (
    <Wrapper component="header">
      <Background />
      <Stack
        direction={"row"}
        alignItems={"center"}
        className="content-wrapper"
        height={"100%"}
        justifyContent={"space-between"}
      >
        <Link to="/">
          <Logo height={70} variant="orange" />
        </Link>
        <DesktopNavigation />
        <MobileNavigation />
      </Stack>
    </Wrapper>
  );
};

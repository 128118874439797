import * as React from "react";
import { StackProps } from "@mui/joy";
import { IconType } from "react-icons";
import {
  ContactItemCardContent,
  ContactItemCardIconWrapper,
  ContactItemCardTitle,
  ContactItemCardWrapper,
  IconWrapper,
} from "./styles";

const IconContainer: React.FC<React.PropsWithChildren & StackProps> = ({
  children,
  ...props
}) => {
  return (
    <IconWrapper
      width={{ xs: "30px", lg: "40px" }}
      height={{ xs: "30px", lg: "40px" }}
      justifyContent={"center"}
      alignItems={"center"}
      {...props}
    >
      {children}
    </IconWrapper>
  );
};

export const ContactItemCard: React.FC<
  React.PropsWithChildren<{ title: string; icon: IconType }>
> = ({ title, icon: Icon, children }) => {
  return (
    <ContactItemCardWrapper direction={"row"} gap={"10px"}>
      <ContactItemCardIconWrapper display={{ xs: "none", md: "block" }}>
        <IconContainer>
          <Icon size={20} />
        </IconContainer>
      </ContactItemCardIconWrapper>
      <ContactItemCardContent>
        <ContactItemCardTitle level="h4">
          <IconContainer display={{ xs: "flex", md: "none" }}>
            <Icon size={20} />
          </IconContainer>
          {title}
        </ContactItemCardTitle>
        {children}
      </ContactItemCardContent>
    </ContactItemCardWrapper>
  );
};

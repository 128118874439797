import newStyled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/joy";

export const Wrapper = newStyled(Stack)`
height: 50px;
border-radius: 25px;
background-color: var(--text-color-transparency-05);
box-shadow: 0 4px 8px var(--text-color-transparency-1);
padding-left: 5px;
`

export const IconWrapper = newStyled(Box)`
width:40px;
height: 40px;
border-radius: 20px;
background: linear-gradient(180deg, rgb(49,204,46), rgba(42,149,40,0.7));
display: flex;
justify-content: center;
align-items: center;
`

export const Text = newStyled(Stack)`
padding: 0 14px;
`

export const Label = newStyled(Typography)`
font-size: 10px;
opacity: 0.8;
`

export const Contact = newStyled(Typography)`
font-size: 16px;
font-weight: 600;
`
import newStyled from "@emotion/styled";
import { Box, IconButton, List, ListItem, ListSubheader, Stack, Typography } from "@mui/joy";

export const FooterWrapper = newStyled(Stack)`
  min-height: var(--footer-height);
  background-color: rgba(180, 180, 180, 0.03);
  border-bottom: 30px solid var(--dark-color);
`;

export const TopSection = newStyled(Stack)`
  min-height: 380px;
  height:auto;
  border-top: 1px solid var(--text-color-transparency-05);
  width: 100%;
`;

export const LogoWrapper = newStyled(Box)`
  width: 260px;
  padding-top:8px;
`;

export const FooterNavigation = newStyled(Stack)`
 width: 100%;
  max-width: 690px;
  padding-top: 0px;
`;

export const NavSectionTitle = newStyled(ListSubheader)`
font-size: 18px;
font-weight: 600;
color: var(--text-color);
padding: 0px;
`;

export const NavSectionRow = newStyled(List)`
padding-top: 0px;
`;

export const NavSectionRowItem = newStyled(ListItem)`
padding: 0px;
`;

export const BottomSection = newStyled(Stack)`
  border-top: 1px solid var(--text-color-transparency-05);
  & > div{
    min-height: 350PX;
    width: 100%;
    padding-top: 20px;
  }
`;

export const CompanyName = newStyled(Typography)`
font-weight: 600;
font-size: 24px;
`

export const BottomSectionRows = newStyled(List)`
gap: 20px;
`

export const BottomSectionTitle = newStyled(ListSubheader)`
font-size: 16px;
font-weight: 600;
color: var(--text-color);
padding: 0px;
margin-bottom: 16px;
`;

export const BottomSectionItem = newStyled(ListItem)`
font-size: 12px;
padding: 0px;
height: 20px;
min-height: 20px;
display:block;
`

export const ContactsRow = newStyled(List)`
gap:8px;
`

export const SocialMediaLink = newStyled(IconButton)`
width: 30px;
height: 30px;
background-color: var(--text-color-transparency-05);
box-shadow: 0 4px 8px var(--text-color-transparency-1);
`
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
} from "@mui/joy";
import { NavLink } from "@types";
import { NavMenuLink } from "../NavMenuLink";

export const NavItem: FC<PropsWithChildren<{ links: NavLink[] }>> = ({
  children,
  links,
}) => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const eventHandler = (event: any) => {
      if (event.target && !wrapper.current?.contains(event.target as Node)) {
        setExpanded(false);
      }
    };

    if (wrapper.current) {
      document.addEventListener("click", eventHandler);
    }

    return () => {
      document.removeEventListener("click", eventHandler);
    };
  }, [wrapper]);

  return (
    <Accordion
      ref={wrapper}
      expanded={expanded}
      onClick={() => {
        setExpanded((expanded) => !expanded);
      }}
    >
      <AccordionSummary>{children}</AccordionSummary>
      <AccordionDetails>
        {links.map((link, index) => (
          <MenuItem key={index}>
            <NavMenuLink nav={link} />
          </MenuItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

import React from "react";
import StylesConfig from "./src/styles/StylesConfig";
import PageLayout from "./src/components/PageLayout";

export const wrapPageElement = ({ element, props }) => {
  const isRoot = props.location.pathname === "/";

  return (
    <StylesConfig>
      <PageLayout {...props} hideHeader={isRoot}>
        {element}
      </PageLayout>
    </StylesConfig>
  );
};

import React from "react";
import axios from "axios";
import { EmailMessageType } from "../types";

export default function useEmailApi({
  message,
  contact,
  name,
}: Partial<EmailMessageType>) {
  const [loading, setLoading] = React.useState(false);
  const [feedback, setFeedBack] = React.useState<
    | {
      error: boolean;
      message: string;
    }
    | undefined
  >(undefined);

  const submitMessage = async () => {
    if (!name || !contact || !message) {
      setFeedBack({ error: true, message: "All fields are mandatory" });
      return;
    }

    setLoading(true);
    setFeedBack(undefined);

    try {
      const response = await axios.put("/api/store-contact-us-message", {
        name,
        contact,
        message,
      });

      if (response.data && response.data.submitted) {
        setFeedBack({
          error: false,
          message: "Message sent",
        });
      } else {
        setFeedBack({
          error: true,
          message: "An error sending the message. Please try again",
        });
      }
    } catch (error) {
      setFeedBack({
        error: true,
        message: "Error sending message",
      });
    }
    setLoading(false);
  };

  return {
    loading,
    feedback,
    clearFeedBack() {
      setFeedBack(undefined);
    },
    submitMessage,
  };
}

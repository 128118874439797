import newStyled from "@emotion/styled";
import { Box } from "@mui/joy";

export const Wrapper = newStyled(Box)`
width: 100%;
height: var(--header-height);
box-shadow: 0 4px 32px rgba(48,48,48,0.08);
position: relative;
`;


export const Background = newStyled(Box)`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 100%);
opacity: 0.15;
`
import React from "react";
import { Link } from "gatsby";
import { NavLink } from "@types";
import useShopFilterEncryption from "@hooks/useShopFilterEncryption";

export const NavMenuLink: React.FC<{ nav: NavLink }> = ({
  nav: { search, label, link },
}) => {
  const { encrypt } = useShopFilterEncryption();
  let to = link;

  if (search) {
    const params = new URLSearchParams();

    Object.keys(search).forEach((key) => {
      params.set(key, encrypt(search[key]));
    });

    to += `?${params}`;
  }

  return (
    <Link
      style={{
        width: "100%",
        height: "100%",
        display: "block",
        padding: "8px",
        fontWeight: 600,
      }}
      to={to}
    >
      {label}
    </Link>
  );
};

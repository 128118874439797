import newStyled from "@emotion/styled";
import { Stack, Box, Typography, Link } from "@mui/joy";

export const GetInTouchWrapper = newStyled(Stack)`
width: 100%;
background-color:var(--text-color-transparency-05);
margin: 50px auto;
border-radius: 8px;
`

export const ContactItemCardWrapper = newStyled(Stack)`
padding: 0.75rem;
background-color: var(--white-color);
border-radius: 8px;
box-shadow: 0 4px 4px var(--text-color-transparency-1);
width: 100%;
`;

export const ContactItemCardIconWrapper = newStyled(Box)`
height: 100%;
`;

export const IconWrapper = newStyled(Stack)`
  border-radius: 50%;
  background-color: var(--secondary-color-transparency-1);
  color: var(--secondary-color);
`

export const ContactItemCardContent = newStyled(Stack)`
width: 100%;
height: 100%;
font-weight: bold;
`;

export const ContactItemCardTitle = newStyled(Typography)`
font-size: 0.75rem;
color: var(--text-color-transparency-5);
text-transform: uppercase;
display: flex;
gap:10px;
align-items: center;
`;

export const MessageFormWrapper = newStyled(Box)`
padding: 35px;
padding-left: 0px;
`

export const SocialMediaLinksWrapper = newStyled(Stack)`
padding: 0px;
`

export const SocialMediaLinksTitle = newStyled(Typography)`
font-size: 1rem;
`

export const SocialMediaLink = newStyled(Link)`
width: 40px;
height: 40px;
border-radius: 8px;
background-image: url('${(props: { bgImage: string }) => props.bgImage}');
background-repeat: no-repeat;
background-size: 40px 40px;
padding: 0px;
box-shadow: 0 0px 8px var(--text-color-transparency-1);
cursor: pointer;
`

import React, { FC } from "react";
import { MdCall } from "react-icons/md";
import { IconType } from "react-icons";
import { IconWrapper, Text, Wrapper, Contact, Label } from "./style";

export const ContactCard: FC<{
  icon: IconType;
  iconBackground?: string;
  label: string;
  contact: string;
}> = ({ label, contact, iconBackground }) => {
  return (
    <Wrapper alignItems={"center"} direction={"row"}>
      <IconWrapper style={{ background: iconBackground }}>
        <MdCall size={20} color="white" />
      </IconWrapper>
      <Text>
        <Label>{label}</Label>
        <Contact>{contact}</Contact>
      </Text>
    </Wrapper>
  );
};

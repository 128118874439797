import newStyled from "@emotion/styled";
import { List, ListItem, Stack, Typography } from "@mui/joy";

export const OurWorkHoursWrapper = newStyled(Stack)`
width: 100%;
margin: 100px auto;
font-size: 1rem;
`;

export const ItemsWrapper = newStyled(List)`
width: 100%;
`

import React, { useEffect, useRef, useState } from "react";
import { Dropdown, IconButton } from "@mui/joy";
import { DropdownProps } from "@mui/base";
import { MdMenu, MdClose } from "react-icons/md";
import { NavLink } from "@types";
import useNavigationData from "@hooks/useNavigationData";
import { NavItem } from "./NavItem";
import { MenuButtonWrapper, NavMenuContainer, NavMenuWrapper } from "./styles";

export const MobileNavigation: React.FC<Omit<DropdownProps, "children">> = (
  props
) => {
  const { pages } = useNavigationData();
  const [navMap, setNavMap] = useState<Record<string, NavLink[]>>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    pages.forEach((page) => {
      let list: NavLink[] = [];

      page.nav?.forEach((nav) => {
        list = list.concat(nav.links);
      });

      setNavMap((map) => ({ ...map, [page.key]: list }));
    });
  }, [pages]);

  const getLinks = (key: string) => navMap[key] || [];

  return (
    <Dropdown
      {...props}
      onOpenChange={(_, open) => setIsOpen(open)}
      open={isOpen}
    >
      <MenuButtonWrapper>
        <IconButton
          size="lg"
          style={{ color: "inherit", backgroundColor: "transparent" }}
        >
          {isOpen ? <MdClose size={28} /> : <MdMenu size={28} />}
        </IconButton>
      </MenuButtonWrapper>
      <NavMenuWrapper>
        <NavMenuContainer>
          <NavItem links={getLinks("solutions")}>Solutions</NavItem>
          <NavItem links={getLinks("shop")}>Shop</NavItem>
          <NavItem links={getLinks("about")}>About</NavItem>
        </NavMenuContainer>
      </NavMenuWrapper>
    </Dropdown>
  );
};

import React, { useCallback, useMemo } from "react";
import { NavType } from "@types";
import useNavigationData from "@hooks/useNavigationData";
import { NavItem, TabsType } from "./NavItem";
import { DesktopNavWrapper } from "./styles";

export const DesktopNavigation: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { pages } = useNavigationData();

  const getTabs = useCallback(
    (key: string) => {
      const page = pages.find((page) => page.key === key);
      const tabs: TabsType = {};

      if (!Array.isArray(page?.nav)) {
        return tabs;
      }

      page.nav.forEach((nav: NavType) => {
        tabs[nav.key] = {
          label: nav.label,
          links: nav.links,
        };
      });

      return tabs;
    },
    [pages]
  );

  const { shopTabs, aboutTabs, solutionsTabs } = useMemo(
    () => ({
      servicesTabs: getTabs("services"),
      solutionsTabs: getTabs("solutions"),
      shopTabs: getTabs("shop"),
      aboutTabs: getTabs("about"),
    }),
    [getTabs]
  );

  return (
    <DesktopNavWrapper
      direction={"row"}
      className={`${className} semi-bold`}
      gap={"8px"}
    >
      {/* <NavItem>our packages</NavItem> */}
      {/* <NavItem tabs={servicesTabs}>services</NavItem> */}
      <NavItem tabs={solutionsTabs}>solutions</NavItem>
      <NavItem tabs={shopTabs}>shop</NavItem>
      <NavItem tabs={aboutTabs}>about</NavItem>
    </DesktopNavWrapper>
  );
};
